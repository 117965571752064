<template>
  <div class="home">
    <boxDetail style="z-index: 9999" :boxDetail="boxDetail"></boxDetail>
    <!-- 页头 -->
    <div class="header_top">
      <handleback :from="'pkHome'" :fightId="fightRoom.id"></handleback>
      <div class="header_center">
        <div v-if="fightRoom.status == 0" class="space_around">
          <svg t="1708852569929" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4002">
            <path
              d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
              fill="#25F484" p-id="4003" />
          </svg>
          等待中
        </div>
        <div v-if="fightRoom.status == 1" class="space_around">
          <svg t="1708853951283" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4300">
            <path
              d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
              fill="#3A97E4" p-id="4301" />
          </svg>
          进行中
        </div>
        <div v-if="fightRoom.status == 2" class="space_around">
          <svg t="1708853861517" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4151">
            <path
              d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
              fill="#727477" p-id="4152" />
          </svg>已结束
        </div>
      </div>
      <div class="plaear">
        <div class="cj" @click="
      copy = true;
    playAudio();
    ">
          创建相同房间
          <div class="cj1"></div>
        </div>
        <div class="fz" @click="
      handlecopy();
    playAudio();
    ">
          复制分享链接
          <div class="fz1"></div>
        </div>
      </div>
    </div>

    <!-- 宝箱轮播图 -->
    <div class="box_list">
      <div class="box_list_box">
        <div class="left box_list_box_scroll">
          <div style="display: flex; opacity: 0.5" v-for="(item, index) in boxList" @click="
      getBoxDetail(item.boxId);
    playAudio();
    " :key="index" :class="num - 1 == index ? 'active box_list_img' : 'box_list_img'">
            <img :src="item.boxImg01|fullPath" alt width="100%" height="100%" />
          </div>
          <!-- <div
            style="display: flex"
            v-for="(item, index) in fightRoom.boxData"
            :key="index"
          >
            <div
              class="box_list_img"
              v-for="(v, i) in item.number"
              :key="v"
              :class="num - 1 == i ? 'active' : ''"
              style="opacity: 0.5"
            >
              <img :src="item.boxImg01" alt width="100%" height="100%" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="money-money box_list_money center">
        <money class="money"></money>
        {{ fightRoom.boxPriceTotal }}
      </div>
    </div>

    <!-- 房间状态 {{ fightRoom.status }} -->
    <!-- 座位信息 -->
    <div class="room_index">
      <div class="room_people space_around">
        <!-- 座位 -->
        <div class="line_box" v-if="flag">
          <!-- <div class="line_box" v-if="true"> -->
          <div class="animation act">
            <div class="heng_xian"></div>
          </div>
        </div>
        <div class="room_seat" :style="{ width: 98 / seats.length + '%' }" v-for="(item, i) in seats" :key="i">
          <!-- 座位有人 -->
          <div class="full_seat" v-if="(item.status == 1 && isready) || item.status == 2 || item.status == 3">
            <lotterySeat class="lotteryRounds" :fightRoom="fightRoom" :playerId="item.playerId"
              :fightResult="fightResult" :seat="item" :singlePlayTime="singlePlayTime" :currentRound="currentRound"
              :svg="svgW" :length="seats.length" @seatReady="seatReady" @myAllEnd="onMyAllEnd"></lotterySeat>
          </div>

          <!-- 座位没人 -->
          <div class="empty_seat" v-else>
            <!-- 等待加入图标 -->
            <div class="room_seat_null center" v-if="fightRoom.userId == $store.state.USER_INFO.userId">
              <i class="el-icon-circle-plus-outline"></i>
            </div>
            <div class="room_seat_null center" @click="
      seatReady();
    playAudio();
    " v-else>
              <i class="el-icon-circle-plus-outline"></i>
            </div>

            <div class="room_seat_name">等待加入...</div>
          </div>
        </div>

        <!-- 动画 结算界面  -->
      </div>
    </div>

    <!-- 确认加入游戏弹窗 -->
    <div class="isjoin_bg" v-if="isjoin">
      <div class="isjoin column">
        <div class="lvbu"></div>
        <div class="isjoin_title">{{ title }}</div>
        <div class="center isjoin_money">
          确定消耗
          <span class="money-money">
            <money class="money"></money>
            {{ fightRoom.boxPriceTotal }}
          </span>
          {{ title }}吗
        </div>
        <div class="isjoin_btn space_around">
          <div class="center isjoin_btn_console" @click="
      isjoin = false;
    playAudio();
    ">
            取消
          </div>
          <div class="center isjoin_btn_true" @click="
      verifyReady();
    playAudio();
    ">
            加入
          </div>
        </div>
      </div>
    </div>
    <div class="isjoin_bg" v-if="copy">
      <div class="isjoin column">
        <div class="lvbu"></div>
        <div class="isjoin_title">{{ title }}</div>
        <div class="center isjoin_money">
          确定消耗
          <span class="money-money">
            <money class="money"></money>
            {{ fightRoom.boxPriceTotal }}
          </span>
          {{ title }}吗
        </div>
        <div class="isjoin_btn space_around">
          <div class="center isjoin_btn_console" @click="
      copy = false;
    playAudio();
    ">
            取消
          </div>
          <div class="center isjoin_btn_true" @click="
      handleCreat();
    playAudio();
    ">
            创建
          </div>
        </div>
      </div>
    </div>
    <!-- 横线 -->
  </div>
</template>

<script>
import lotterySeat from "@/components/pkHome/lotterySeat.vue";
// import countTo from "vue-count-to";
// import lotteryRounds from "@/components/pkHome/lotteryRounds.vue";
/* import Vue from 'vue' */

// import store from "@/vuex/store";
// import { scoket } from "@/api/scoket";
import boxDetail from "./boxDetail.vue";
import {
  audience,
  getUserInfo,
  ApiSeatReady, //玩家准备
  Playthegame, //开始游戏
  Gameover, //游戏结束
  joinFightverify,
  createFight,
} from "@/api/index";

import bsurl from "@/api/basurl.js";

export default {
  data() {
    return {
      num: 0,
      copy: false,
      //老虎机滚动时间
      singlePlayTime: 4,
      //debug 老虎机实时速度
      debugSpeed: null,
      resultLocation: new Map(),
      // 对战结果集（后端数据结构）
      // private Integer WinnerId;
      // // 开箱结果
      // private List<TtBoxRecords> fightResult;
      // // 宝箱详情
      // private List<FightBoxVO> fightBoxVOList;
      // 整理后是map
      fightResult: new Map(),
      fightRoom: {
        boxData: [],
      }, // 对战房间信息
      seats: {}, // 对战房间信息
      winnerId: null,
      location_y: 0,
      // 本局奖金总额
      // 完成抽奖的人数
      allEndNumber: 0,
      roomstatus: null,
      loading: true, //动画结束的显示隐藏
      begin: false,
      title: "",
      isjoin: false,
      id: this.$route.query.id,
      roomList: [], //全部数据,
      currentRound: 1,
      svgW: 0, //svg图标的尺寸
      flag: false, //是否展示横线
      boxDetail: {
        flag: false,
        boxId: ''
      },
      isready: false
    };
  },
  computed: {
    boxList() {
      let arr = [];
      let obj = this.fightRoom.boxData;
      let key;
      for (key in obj) {
        for (let i = 1; i <= obj[key].number; i++) {
          arr.push(obj[key]);
        }
      }
      //this.fightRoom.boxData.forEach((item) => {
      //for (let i = 1; i < item.number; i++) {
      //arr.push(item)
      //}
      //});
      return arr;
    },
  },
  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    getBoxDetail(boxId) {
      this.boxDetail.flag = true
      this.boxDetail.boxId = boxId
    },
    handleCreat() {
      let boxObj = {};
      let key;
      for (key in this.fightRoom.boxData) {
        boxObj[key] = this.fightRoom.boxData[key].number;
      }
      createFight({
        boxIdAndNumber: boxObj,
        model: this.fightRoom.model,
        playerNumber: this.fightRoom.playerNum,
      }).then((res) => {
        if (res.data.code == 200) {
          this.copy = false;
          // 提示
          this.$message({
            type: "success",
            message: "创建成功",
            customClass: "log_success",
          });

          // 跳转
          this.$router.replace({
            name: "battleroom",
            query: {
              id: res.data.data.id,
              uid: this.userId,
            },
          });
          this.$router.go(0);
        } else {
          this.$message({
            type: "warning",
            message: res.data.msg,
            customClass: "log_warning",
          });
        }
      });
    },
    onMyAllEnd() {
      this.allEndNumber++;
      if (this.allEndNumber == this.seats.length) {
        setTimeout(() => {
          this.flag = false;
          this.fightRoom.status = 2;
          Gameover(this.fightRoom.id).then(() => {
          });
        }, 1000);
      }
    },
    onSpeedChange(speed) {
      this.debugSpeed = speed;
    },
    resLocationChange(locationMap) {
      this.resultLocation = locationMap;
    },
    roomIsFull(seats) {
      let flag = true;
      for (let index = 0; index < seats.length; index++) {
        let seat = seats[index];
        if (seat.status == "0" || seat.status == "1") {
          flag = false;
          break;
        }
      }
      return flag;
    },
    roomIsReady(seats) {
      let flag = true;
      for (let index = 0; index < seats.length; index++) {
        let seat = seats[index];
        if (seat.status != "2") {
          flag = false;
          break;
        }
      }
      return flag;
    },
    //建立ws连接
    connectWs() {
      let fid = this.$route.query.id;
      let uid = this.$route.query.uid;
      this.$sockets = new WebSocket(
        bsurl.BASE_API + "/ws/fight/room/" + uid + "/" + fid
      );

      this.$sockets.onopen = () => {
        //console.log("fightRoom ==> onopen");
      };

      this.$sockets.onmessage = (event) => {
        //console.log("fightRoom ==> onmessage", event);

        let msg = JSON.parse(event.data);
        // 判断消息类型
        if (msg.key == "FIGHT_ROOM_INFO") {
          // 房间信息
          let fight = msg.data;

          console.log(msg)
          let seats = fight.seats;
          this.fightRoom = fight;
          this.seats = seats;
          if (fight.winnerIds) {
            this.winnerIds = fight.winnerIds;
          }


          // this.$message({
          //   type: "success",
          //   message: "id:" + fid + " 人数=" + seats.length,
          //   customClass: "log_success",
          // });

          // 如果所有人已就绪，直接开始
          if (this.roomIsReady(seats)) {

            this.fightBegin();
          }
        } else if (msg.key == "FIGHT_RESULT") {
          if (msg.data.currentRound) {
            this.currentRound = msg.data.currentRound;
          }
          // 对战结果
          this.fun(msg.data);
          if (msg.data.fight.status == 1) {
            this.flag = true;
          } else if (msg.data.fight.status == 2 || msg.data.fight.status == 3) {
            this.flag = false;
          }

        }
      };
      this.$sockets.onclose = () => {
        if (this.fightRoom.status == 0) {
          this.$sockets = new WebSocket(
            bsurl.BASE_API + "/ws/fight/room/" + uid + "/" + fid
          );
        } else {
          this.roomList = [];
        }
      };
    },
    //开启横线
    handleflag() {
      this.flag = true;
    },
    //关闭横线
    closeflag() {
      this.flag = false;
    },
    //整理数据
    fun(vo) {
      // 整理数据
      let fightRes = vo.fightResult;
      let fightBox = vo.fightBoxVOList;

      // 结果集补充宝箱信息
      for (let record of fightRes) {
        for (let box of fightBox) {
          if (record.boxId == box.boxId) {
            record.boxInfo = box;
            break;
          }
        }
      }

      // 结果集按人分组
      let fightResult = new Map();
      let seats = vo.fight.seats;

      let newSeats = [];
      for (let index = 0; index < seats.length; index++) {
        if (vo.winnerIds.includes(seats[index].playerId)) {
          seats[index].isWinner = true;
        } else {
          seats[index].isWinner = false;
        }

        let playerOwnList = [];
        for (let record of fightRes) {

          if (record.userId == seats[index].playerId) {
            playerOwnList.push(record);
          }
        }
        newSeats.push(seats[index]);

        fightResult.set(seats[index].playerId, playerOwnList);
      }
      this.fightResult = fightResult;
      this.winnerId = vo.WinnerId;
      this.seats = newSeats;
      vo.fight.seats = newSeats;
      this.fightRoom = vo.fight;
      if (this.fightRoom.status == 2 || this.fightRoom.status == 3) {
        this.lotteryEnd = true;
      }
      this.isready = true
      // this.seatRefresh = new Date();

    },
    //准备游戏
    seatReady() {
      this.isjoin = true;
    },

    //开始游戏
    fightBegin() {
      let fid = this.$route.query.id;
      Playthegame(fid).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: " 游戏开始",
            customClass: "log_success",
          });
        } else {
          this.$message({
            type: "warning",
            message: res.data.msg,
            customClass: "log_warning",
          });
        }
      });
    },

    formatter(value) {
      // 格式化函数，可以根据需要自定义
      return value.toFixed(2); // 格式化为本地数字字符串
    },
    Ready(fid) {
      ApiSeatReady(fid).then((res) => {
        if (res.data.code == 200) {
          getUserInfo().then((res) => {
            this.$store.commit("USER_INFO", res.data.data);
          });

          this.isjoin = false;
        } else {
          this.seatReady(fid)
        }
      });
    },
    //确定准备
    verifyReady() {
      joinFightverify({ fightId: this.$route.query.id }).then((res) => {
        if (res.data.code == 200) {
          let fid = this.$route.query.id;
          this.Ready(fid)
        } else {
          this.$message({
            type: "warning",
            message: res.data.msg,
            customClass: "log_warning",
          });
        }
      });
    },

    ///  复制分享链接
    async handlecopy() {
      const url = window.location.href; // 获取当前地址栏的URL
      const textarea = document.createElement("textarea");
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      // 执行复制操作
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          // 复制成功，使用 Element UI 的 $message 显示通知
          this.$message({
            message: "复制成功",
            type: "success",
            customClass: "log_success",
          });
        } else {
          // 复制失败，显示错误信息
          this.$message({
            message: "浏览器版本过低,请升级浏览器",
            type: "warning",
            customClass: "log_warning",
          });
        }
      } catch (err) {
        this.$message({
          message: "浏览器版本过低,请升级浏览器",
          type: "warning",
          customClass: "log_warning",
        });
      }

      // 移除textarea元素
      document.body.removeChild(textarea);
    },
  },
  destroyed() { },
  components: {
    boxDetail,
    lotterySeat,
  },
  beforeCreate() { },
  // computed: { countTo },
  created() {
    this.$bus.$on("num", (data) => {
      this.num = data;
    });
    // 请求api获取用户信息
    getUserInfo().then((res) => {
      if (res.data.code == 200) {
        this.userId = res.data.data.userId;
        this.$store.commit("USER_INFO", res.data.data);
        // 建立ws连接
      } else {
        this.$message({
          message: "获取用户信息失败，请重新登录",
          type: "warning",
          customClass: "log_warning",
        });
      }
    });
  },
  mounted() {
    if (document.body.clientWidth >= 550) {
      this.svgW = "200";
    } else {
      this.svgW = document.body.clientWidth * 0.4;
    }
    //屏幕尺寸变化就重新赋值
    window.onresize = () => {
      return (() => {
        if (document.body.clientWidth >= 550) {
          this.svgW = "200";
        } else {
          this.svgW = document.body.clientWidth * 0.4;
        }
      })();
    };
    audience(this.$route.query.id).then((res) => {

      if (res.data.code == 200) {
        let data = res.data.data;
        if (res.data.data.fight.status == 1) {
          this.flag = true;
        }
        console.log("观战数据", data);
        this.currentRound = data.currentRound;
        //整理数据并渲染
        this.fun(data);
      } else if (res.data.code == 601) {
        this.connectWs();
      } else {
        this.$message({
          type: "warning",
          message: res.data.msg,
          customClass: "log_warning",
        });
      }
    });
  },
  beforeDestroy() {
    this.$sockets.close();
  },
};
</script>

<style lang="scss" scoped>
.active {
  opacity: 1 !important;
}

.pk_result {
  margin-top: 14px !important;
}

.result_box {
  width: 115px;
  height: 583px;
  margin: 0 auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }

  @media (max-width: 550px) {
    // width: 75px;
    // height: 80px;
    // height: 482px;
  }
}

.result_weapon {
  margin-top: 7px;
  width: 105px;
  height: 112px;
  background: rgba(50, 52, 54, 0.25);
  border-radius: 7px 7px 7px 7px;
  background-size: 100% 100%;

  // @media (max-width: 550px) {
  //   width: 75px;
  //   height: 80px;
  // }
  img {
    width: 56px;
    height: 56px;
    // @media (max-width: 550px) {
    //   width: 40px;
    //   height: 40px;
    // }
  }
}

.result_weapon_name {
  font-size: 10px;
}

.result_weapon_money {
  font-size: 11px;
}

.result_icon {
  margin: 28px 0;

  @media (max-width: 550px) {
    margin: 20px 0;
  }

  .icon {
    width: 70px;
    height: 62px;

    @media (max-width: 550px) {
      width: 50px;
      height: 50px;
    }
  }
}

.battle_ainmate_on {
  position: absolute;
  width: 100%;
  width: 100%;
  height: 132px;
  background: rgba(16, 17, 21, 1);
  border-radius: 14px;
  top: 22%;
  overflow: hidden;

  @media (max-width: 550px) {
    top: 15%;
    height: 117px;
  }
}

.isjoin_bg {
  position: fixed;
  width: 525px;
  height: 100vh;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  padding-top: 10px;
  background: rgba(0, 0, 0, 0.85);
  z-index: 5;

  @media (max-width: 550px) {
    width: 100%;
  }

  .lvbu {
    border-radius: 21px 21px 21px 21px;
    width: 100%;
    left: 0;
    top: 0;

    height: 100%;
    background: linear-gradient(-219deg,
        rgba(37, 244, 132, 0.2) 0%,
        rgba(32, 29, 48, 0) 40%,
        rgba(248, 125, 81, 0) 100%);
    position: absolute;
  }

  .isjoin {
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: 15%;
    height: 231px;
    background: rgba(16, 17, 21, 1);
    border-radius: 21px;

    @media (max-width: 550px) {
      height: 182px;
    }

    .isjoin_money {
      font-size: 23px;
      margin: 10% auto 0;

      @media (max-width: 550px) {
        font-size: 17px;
      }
    }

    .isjoin_title {
      font-size: 28px;

      @media (max-width: 550px) {
        font-size: 22px;
      }
    }

    .isjoin_btn {
      width: 98%;
      margin: 10% auto 0;
      position: relative;
      z-index: 2;
      font-size: 23px;

      .isjoin_btn_console {
        width: 40%;
        height: 42px;
        background: linear-gradient(360deg,
            rgba(114, 116, 119, 0.5) 0%,
            rgba(114, 116, 119, 0.25) 100%);
        opacity: 1;
        border: 2px solid #727477;

        @media (max-width: 550px) {
          border: 1px solid #727477;
          width: 154px;
          height: 33px;
          font-size: 17px;
        }
      }

      .isjoin_btn_true {
        width: 40%;
        height: 42px;
        background: linear-gradient(360deg,
            rgba(37, 244, 132, 0.5) 0%,
            rgba(37, 244, 132, 0.25) 100%);
        opacity: 1;
        border: 2px solid #25f484;

        @media (max-width: 550px) {
          border: 1px solid #25f484;
          width: 154px;
          height: 33px;
          font-size: 17px;
        }
      }
    }
  }
}

.room_index {
  position: relative;

  .room_people {
    margin: 2% auto 0;

    .room_seat {
      // border: 1px solid;
      height: 860px;
      padding: 3% 0 0 0;
      background: linear-gradient(239deg,
          rgba(114, 116, 119, 0.1) 0%,
          rgba(50, 52, 54, 0.05) 100%);

      border-radius: 14px 14px 14px 14px;

      .full_seat {
        position: relative;
        height: 100%;

        .lotteryRounds {
          // display: none;
          margin-left: 2%;
          margin-right: 2%;
          // border: 1px solid;
          position: relative;
          height: 100%;
        }
      }

      .room_seat_img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0 auto;
        overflow: hidden;

        @media (max-width: 550px) {
          width: 40px;
          height: 40px;
          font-size: 9px;
        }
      }

      .room_seat_null {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        background: rgba(37, 244, 132, 0.2);
        opacity: 1;
        border: 1px solid #25f484;
        border-radius: 50%;

        i {
          color: rgba(37, 244, 132, 1);
          font-size: 23px;
        }

        @media (max-width: 550px) {
          width: 40px;
          height: 40px;

          i {
            font-size: 17px;
          }
        }
      }

      .room_seat_name {
        margin: 10% auto 0;

        font-size: 13px;
      }

      .room_seat_btn {
        margin: 3% auto 0;
        font-size: 18px;
        width: 70%;
        height: 35px;

        @media (max-width: 550px) {
          margin: 7% auto 0;

          width: 70px;
          height: 25px;
          font-size: 13px;
        }
      }

      .animate_list {
        z-index: 1;
        display: flex;
        width: 92px;
        height: 105px;
        margin: 0 auto;
        margin-top: 20px;
        overflow-y: hidden;
        position: relative;

        .an .list_animate {
          transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0.1s;
          transform: translateY(-1947px);
        }

        .list_box {
          width: 92px;
          height: 98px;
          background-size: 100% 100%;
        }
      }

      ._ready {}

      ._wait {
        background: linear-gradient(360deg,
            rgba(37, 244, 132, 0.5) 0%,
            rgba(37, 244, 132, 0.25) 100%);

        border: 1px solid #25f484;
      }
    }
  }
}

.box_list_money {
  width: 28%;
  margin: 14px auto 0;
  height: 35px;
  background: #323436;
  border-radius: 35px 35px 35px 35px;

  @media (max-width: 550px) {
    margin: 10px auto 0;

    width: 100px;
    font-size: 10px;
    height: 25px;

    .money-money {
      width: 15px;
      height: 15px;
    }
  }
}

.box_list_box {
  width: 100%;
  height: 87px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    height: 59px;

    &::-webkit-scrollbar {
      height: 2px;
    }
  }
}

.box_list_box_scroll {
  position: absolute;
  // width: 500px;
  // position: absolute;
  /* display: block; */
  height: 71px;

  // left: -50%;
  @media (max-width: 550px) {
    height: 51px;
  }
}

.box_list_img {
  width: 70px;
  height: 70px;
  overflow: hidden;
  margin: 0 14px;

  @media (max-width: 550px) {
    margin: 0 10px;

    width: 50px;
    height: 50px;
  }
}

.box_list {
  width: 100%;
  height: 155px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  padding-top: 10px;

  @media (max-width: 550px) {
    // width: 355px;
    height: 107px;
  }
}

.home {
  width: 95%;
  margin: 0 auto;
}

.header_top {
  width: 100%;
  height: 56px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {}

  .plaear {
    color: #fff;
    font-size: 14px;
    display: flex;

    @media (max-width: 550px) {
      font-size: 10px;
    }
  }

  .cj {
    width: 84px;
    // height: 3px;
    margin-right: 8px;

    @media (max-width: 550px) {
      width: 60px;
    }
  }

  .cj1 {
    width: 100%;
    height: 3px;
    background: #25f484;
    border-radius: 5px;

    @media (max-width: 550px) {
      height: 2px;
    }
  }

  .fz {
    width: 84px;

    // height: 3px;
    @media (max-width: 550px) {
      width: 60px;
    }
  }

  .fz1 {
    width: 100%;
    height: 3px;
    background: #3a97e4;
    border-radius: 5px;

    @media (max-width: 550px) {
      height: 2px;
    }
  }
}

.header_center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  font-size: 21px;
  font-weight: 500;

  @media (max-width: 550px) {
    font-size: 15px;
    width: 70px;
  }

  .icon {
    width: 21px;
    height: 21px;
    animation: infinite-spin 5s linear infinite;

    @media (max-width: 550px) {
      width: 15px;
      height: 15px;
    }
  }
}

.list-item-enter-active,
.list-item-leave-active {
  transition: all 1s ease;
  /* 设置过渡动画的时间和效果 */
}

.list-item-enter,
.list-item-leave-to {
  transform: translateY(-30px);
  /* 设置起始和结束位置的偏移量 */
  opacity: 0;
  /* 设置起始和结束时的透明度 */
}

.xialuo {}

.line_box {
  position: absolute;
  top: 159px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 498.75px;

  @media (max-width: 550px) {
    width: 95vw;
    height: 20vw;
  }

  height: 110px;
  // // border: 1px solid yellow;

  .animation {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // border: 1px solid palegreen;
    position: relative;
  }

  .act {
    animation: hengxian 0.5s linear;
  }

  @keyframes hengxian {
    0% {
      width: 0%;
    }

    52% {
      width: 50%;
    }

    100% {
      width: 100%;
    }
  }
}

.line {
  border-bottom: 2px solid red;
}

.heng_xian {
  position: absolute;
  z-index: 99;
  top: 50%;
  margin-top: -1.5px;
  width: 100%;
  height: 3px;
  background-color: #3a97e4;
  left: 0;
  opacity: 0.87;
  margin: 0 auto;
}

.heng_xian:before {
  position: absolute;
  top: -50%;
  margin-top: -6px;
  left: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  border-width: 9px;
  border-left-color: #3a97e4;
}

.heng_xian:after {
  position: absolute;
  top: -50%;
  margin-top: -6px;
  right: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  border-width: 9px;
  border-right-color: #3a97e4;
}

.center_number {
  width: 28px;
  height: 28px;
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  background: #25f484;
  border-radius: 50%;

  @media (max-width: 550px) {
    font-size: 13px;
    width: 21px;
    height: 21px;
  }
}
</style>
